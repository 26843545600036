
*, *::after, *::before{
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

.css-2613qy-menu{
  background-color: blue;
}

.Select-menu-outer {
  z-index: 999 !important;
  position: relative;
}

.MuiTableCell-root{
  font-size: 1.5rem;
}

.detail{
  font-size: 0.98rem!important;
  color: blue;
}
.total{
  font-size: 1.02rem!important;
  font-weight: bold!important;
}
